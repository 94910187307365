import React from "react";
import "../styles/Footer.css";
import "../styles/Contact.css";
import { NavLink } from "react-router-dom";

import { useLocation } from "react-router-dom";

import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaUserAlt,
  FaHome,
  FaSuitcase,
} from "react-icons/fa";

function Footer() {
  const contactDetails = [
    {
      icon: <FaEnvelope />,
      label: "Email",
      value: "amrtrj68@gmail.com",
      link: "mailto:amrtrj68@gmail.com",
    },
    {
      icon: <FaPhone />,
      label: "Phone",
      value: "+91 8789371964",
      link: "tel:8789371964",
    },
    {
      icon: <FaMapMarkerAlt />,
      label: "Address",
      value: "Greater Noida, India",
      link: "https://www.google.com/maps?q=28.475327007538173, 77.48713482343756",
    },
  ];

  const location = useLocation();

  // Check if the current path is '/contact'
  const isContactPage = location.pathname === "/contact";
  const isAdmin = location.pathname === "/adminamrit";

  return (
    <>
      {isAdmin ? (
        <></>
      ) : (
        <div className="inconsolata-family">
          <div className="ar-spacer-hr-sm"></div>

          <div className="container ar-background-upper-footer">
            <div className="row display-icons">
              <div className="col-12 col-lg-8 col-sm-12 col-md-12 col-xl-8 upper-footer-left-pannel">
                <div className="ar-section-padding" data-aos="fade-right">
                  <div data-aos="fade-left">
                    <h5 className="ar-highlighted-text ar-section-heading ">
                      Let’s Make Great Things Happen!
                    </h5>
                  </div>
                  <h1>Reach Out to Me</h1>
                  <p className="ar-light-grey">
                    I’m eager to connect with fellow developers, potential
                    collaborators, and new clients. Whether you have a project
                    in mind or just want to discuss technology, feel free to
                    reach out. I'm here and excited to explore new opportunities
                    with you. Looking forward to hearing from you!
                  </p>
                  <div className="ar-spacer-hr-lg"></div>

                  <div className="footer-informations ar-flex-between row">
                    {contactDetails.map((contact) => (
                      <div className="col-12 col-lg-4 col-xl-4 ">
                        <a
                          href={contact.link}
                          style={{ textDecoration: "none" }}
                          target="_blank"
                        >
                          <p className="phone-email-address-div">
                            <span className="ar-highlighted-text phone-email-address-icon">
                              {contact.icon}
                            </span>
                            <span className="link-span ar-primary-color">
                              {contact.value}
                            </span>
                          </p>
                        </a>
                      </div>
                    ))}
                  </div>
                  <div className="ar-spacer-hr-sm"></div>

                  <div className="row">
                    <div className="col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6">
                      {isContactPage ? (
                        <></>
                      ) : (
                        <NavLink to="/contact" className="web-view">
                          <button
                            className="btn-flip transparent-bg-btn"
                            data-back="Let's Connect"
                            data-front="Contact Me"
                          ></button>
                        </NavLink>
                      )}
                    </div>

                    <div
                      className="col-12 col-lg-6 col-xl-6 footer-bottom-icons"
                      style={{ justifyContent: "flex-start" }}
                    >
                      {[
                        {
                          href: "https://www.linkedin.com/in/amrit-raj-611447128/",
                          icon: "linkedin",
                        },
                        {
                          href: "https://github.com/amritaadeez",
                          icon: "github",
                        },
                        {
                          href: "https://www.instagram.com/_amrit_itsaadeez/",
                          icon: "instagram",
                        },
                        { href: "mailto:amrtrj68@email.com", icon: "mail" },
                        { href: "https://x.com/amrtrj68", icon: "twitter" },
                      ].map(({ href, icon }, index) => (
                        <React.Fragment key={icon}>
                          <a
                            target="_blank"
                            className="social-media-icon"
                            rel="noopener noreferrer"
                            href={href}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className={`lucide lucide-${icon} opacity-container-child`}
                            >
                              {icon === "linkedin" && (
                                <>
                                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                                  <rect
                                    width="4"
                                    height="12"
                                    x="2"
                                    y="9"
                                  ></rect>
                                  <circle cx="4" cy="4" r="2"></circle>
                                </>
                              )}
                              {icon === "github" && (
                                <>
                                  <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4"></path>
                                  <path d="M9 18c-4.51 2-5-2-7-2"></path>
                                </>
                              )}
                              {icon === "instagram" && (
                                <>
                                  <rect
                                    width="20"
                                    height="20"
                                    x="2"
                                    y="2"
                                    rx="5"
                                    ry="5"
                                  ></rect>
                                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                                  <line
                                    x1="17.5"
                                    x2="17.51"
                                    y1="6.5"
                                    y2="6.5"
                                  ></line>
                                </>
                              )}
                              {icon === "mail" && (
                                <>
                                  <rect
                                    width="20"
                                    height="16"
                                    x="2"
                                    y="4"
                                    rx="2"
                                  ></rect>
                                  <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                                </>
                              )}
                              {icon === "twitter" && (
                                <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                              )}
                            </svg>
                          </a>
                          {index < 4 && <span className="separator"></span>}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <div className="ar-spacer-hr-sm"></div> */}
                <div className="row ar-section-padding">
                  <div className="col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6"></div>
                  <div className="col-12 col-lg-6 col-sm-12 col-md-12 col-xl-6 footer-bottom-text">
                    © 2025 Amrit Raj. All rights reserved.
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-sm-12 col-md-12 col-xl-4 background-amrit "></div>
            </div>
          </div>
          <div className="ar-spacer-hr-lg"></div>

          <div className="ar-spacer-hr-sm mob-view"></div>
          <div className="ar-spacer-hr-sm mob-view"></div>
          <div className="ar-spacer-hr-sm mob-view"></div>
          <div className="ar-spacer-hr-sm mob-view"></div>

          <div className="footer-mobile mob-view container">
            <div className="mobile-view">
              <div className=" nav-item">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link footer-link active"
                      : "nav-link footer-link"
                  }
                >
                  <FaHome />
                </NavLink>
              </div>
              <div className=" nav-item">
                <NavLink
                  to="/profile"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link footer-link active"
                      : "nav-link footer-link"
                  }
                >
                  <FaUserAlt />
                </NavLink>
              </div>

              <div className=" nav-item">
                <NavLink
                  to="/projects"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link footer-link active"
                      : "nav-link footer-link"
                  }
                >
                  <FaSuitcase />
                </NavLink>
              </div>

              <div className=" nav-item">
                <NavLink
                  to="/contact"
                  className={({ isActive }) =>
                    isActive
                      ? "nav-link footer-link active"
                      : "nav-link footer-link"
                  }
                >
                  <FaEnvelope />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Footer;
