import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { FaCog } from "react-icons/fa";
import { CustomColorPicker } from "./customColorPicker";
import '../styles/ThemeMenu.css';

// Create a Context for the theme
const ThemeContext = createContext();

// Custom hook to use the theme context
export const useTheme = () => {
  return useContext(ThemeContext);
};

// Helper function to convert hex to RGB
const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : null;
};

// Provider component to wrap the app and provide theme context
export const ThemeProvider = ({ children }) => {
  let themes;
  themes = localStorage.getItem("primary-blue")
    ? "primary-blue"
    : localStorage.getItem("primary-green")
    ? "primary-green"
    : "primary-red";
  const [theme, setTheme] = useState(themes);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // Close the menu if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        event.target !== document.querySelector(".theme-toggle-btn")
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Update CSS variables when theme changes
  useEffect(() => {
    if (theme === "primary-blue") {
      const primaryColor = "#0d5075";
      // Set other theme variables
      localStorage.setItem("primary-blue", "true");
      localStorage.removeItem("primary-red");
      localStorage.removeItem("primary-green");
      
      // Add transition property before changing colors
      document.documentElement.style.setProperty("--color-transition", "all 0.3s ease");
      document.documentElement.style.cssText = `
        --primary-color: ${primaryColor};
        --primary-color-rgb: ${hexToRgb(primaryColor)};
        --border-color: #0d5075;
        --font-primary-color: #0d5075;
        --button-primary-color: #0d5075;
        --link-color: #695a60;
        --link-hover-color: #01579b;
        --link-active-color: #0d5075;
        --text-primary: #0d5075;
        --text-light: #ebe8e4;
        --footer-bg: #89a0bb2e;
        --light-primary-gradient-color: #56aaff;
      `;
    } else if (theme === "primary-green") {
      const primaryColor = "#0d9274";
      // Set other theme variables
      localStorage.setItem("primary-green", "true");
      localStorage.removeItem("primary-red");
      localStorage.removeItem("primary-blue");
      document.documentElement.style.setProperty("--primary-color", primaryColor);
      document.documentElement.style.setProperty("--primary-color-rgb", hexToRgb(primaryColor));
      document.documentElement.style.setProperty("--border-color", "#0d9274");
      document.documentElement.style.setProperty(
        "--font-primary-color",
        "#0d9274"
      );
      document.documentElement.style.setProperty(
        "--button-primary-color",
        "#0d9274"
      );
      document.documentElement.style.setProperty("--link-color", "#609589");
      document.documentElement.style.setProperty(
        "--link-hover-color",
        "#388E3C"
      );
      document.documentElement.style.setProperty(
        "--link-active-color",
        "#0d9274"
      );
      document.documentElement.style.setProperty("--text-primary", "#0d9274");
      document.documentElement.style.setProperty("--text-light", "#ebe8e4");
      document.documentElement.style.setProperty("--footer-bg", "#76987829");
      document.documentElement.style.setProperty(
        "--light-primary-gradient-color",
        "#81C784"
      );
    } else {
      const primaryColor = "#d55882";
      // Set other theme variables
      localStorage.setItem("primary-red", "true");
      localStorage.removeItem("primary-blue");
      localStorage.removeItem("primary-green");
      document.documentElement.style.setProperty("--primary-color", primaryColor);
      document.documentElement.style.setProperty("--primary-color-rgb", hexToRgb(primaryColor));
      document.documentElement.style.setProperty("--border-color", "#8b5a6c");
      document.documentElement.style.setProperty(
        "--font-primary-color",
        "#d55882"
      );
      document.documentElement.style.setProperty(
        "--button-primary-color",
        "#d55882"
      );
      document.documentElement.style.setProperty("--link-color", "#695a60");
      document.documentElement.style.setProperty(
        "--link-hover-color",
        "#be6284"
      );
      document.documentElement.style.setProperty(
        "--link-active-color",
        "#d55882"
      );
      document.documentElement.style.setProperty("--text-primary", "#d55882");
      document.documentElement.style.setProperty("--text-light", "#ebe8e4");
      document.documentElement.style.setProperty("--footer-bg", "#ff000011");
      document.documentElement.style.setProperty(
        "--light-primary-gradient-color",
        "#ff80a6"
      );
    }
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="setting-div">
        <button 
          className="theme-toggle-btn" 
          onClick={toggleMenu}
          aria-label="Theme Settings"
        >
          <FaCog className="cog-icon" color="white" />
        </button>

        <div 
          className={`theme-menu ${isMenuOpen ? "show" : ""}`} 
          ref={menuRef}
        >
          <div className="theme-menu-header">
            <div className="header-content">
              <h6>Theme Settings</h6>
              <button 
                className="theme-menu-close" 
                onClick={() => setIsMenuOpen(false)}
                aria-label="Close Theme Settings"
              >
                ×
              </button>
            </div>
          </div>

          <div className="theme-content">
            <div className="theme-layout">
              <div className="theme-presets">
                <p className="theme-section-title">Preset Themes</p>
                <div className="theme-options">
                  {[
                    {
                      name: "primary-red",
                      label: "Classic Red",
                      gradient: "linear-gradient(45deg, #d55882, #ff80a6)",
                    },
                    {
                      name: "primary-blue",
                      label: "Cool Blue",
                      gradient: "linear-gradient(45deg, #0d5075, #56aaff)",
                    },
                    {
                      name: "primary-green",
                      label: "Eco Green",
                      gradient: "linear-gradient(45deg, #0d9274, #81C784)",
                    },
                  ].map(({ name, label, gradient }) => (
                    <div
                      key={name}
                      className={`theme-option ${theme === name ? 'active' : ''}`}
                      onClick={() => {
                        toggleTheme(name);
                        setIsMenuOpen(false);
                      }}
                    >
                      <div 
                        className="theme-color-preview" 
                        style={{ background: gradient }}
                      />
                      <div className="theme-option-details">
                        <span className="theme-option-label">{label}</span>
                        {theme === name && (
                          <span className="theme-option-active">
                            <svg viewBox="0 0 24 24" width="16" height="16">
                              <path 
                                fill="currentColor" 
                                d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
                              />
                            </svg>
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="theme-custom">
                <p className="theme-section-title">Custom Theme</p>
                <CustomColorPicker theme={theme} setTheme={setTheme} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {children}
    </ThemeContext.Provider>
  );
};
