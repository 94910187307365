import React, { useState, useEffect } from "react";
import "../styles/Contact.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import apiService from "../widget/api";
import Loader from "../context/Loader";
import AOS from "aos";
import amrit from "../assets/frontend-dev.png";
import { FaSpinner } from "react-icons/fa";
function Contact() {
  useEffect(() => {
    AOS.init({
      duration: 500, // Animation occurs only once
    });
  }, []);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    navigate("/contact");
    window.scrollTo(0, 0);
  }, [navigate]);

  const [isLoading, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const error = validateField(name, value);
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  //   form validation
  const validateForm = () => {
    const newErrors = {};
    Object.keys(formData).forEach((name) => {
      const error = validateField(name, formData[name]);
      if (error) {
        newErrors[name] = error;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "firstName":
        if (!value.trim()) {
          error = "First Name is required";
        }
        break;
      case "lastName":
        if (!value.trim()) {
          error = "Last Name is required";
        }
        break;
      case "phone":
        if (!value.trim() || !/^[0-9]{10}$/.test(value)) {
          error = "A valid 10-digit phone number is required";
        }
        break;
      case "email":
        if (!value.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "A valid email address is required";
        }
        break;
      case "message":
        if (!value.trim()) {
          error = "Message is required";
        }
        break;
      default:
        break;
    }

    return error;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    setLoader(true);
    try {
      const response = await apiService.submitContactForm(formData);
      // Reset form fields
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
      });
      setLoader(false);
      setErrors({});
      setFormSubmitted(true);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  return (
    <div className="inconsolata-family ar-section-padding container ">
      <span className="jump-letter ar-step-count bg-name right-10 web-view">
        AMRIT{" "}
      </span>
     
      <div className="ar-spacer-hr-sm"></div>
      {/* <div className="ar-spacer-hr-lg"></div> */}
      {formSubmitted ? (
        <div className="ar-flex-center" style={{ flexDirection: "column" }}>
          <img data-aos="fade-right" src={amrit} data-aos-delay="100"  height={200}/>
          <h5
            className="ar-highlighted-text"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Thank you for reaching out!
          </h5>
          <div className="contact-info" data-aos="fade-up" data-aos-delay="300">
            <h6 className="ar-light-grey" style={{ textAlign: "center" }}>
              Your message has been received and appreciate you reaching out.
              I'll get back to you as soon as possible. Looking forward to
              connecting with you.
            </h6>
          </div>
          <div
            className="row display-icons"
            data-aos="fade-right"
            data-aos-delay="400"
            style={{ gap: "20px !important" }}
          >
            <div
              className="col-12 ar-flex-start footer-bottom-icons"
              style={{ justifyContent: "flex-start" }}
            >
              <a
                target="_blank"
                className="social-media-icon"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/amrit-raj-611447128/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-linkedin opacity-container-child"
                >
                  <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                  <rect width="4" height="12" x="2" y="9"></rect>
                  <circle cx="4" cy="4" r="2"></circle>
                </svg>
              </a>
              <a
                target="_blank"
                className="social-media-icon"
                rel="noopener noreferrer"
                href="https://github.com/amritaadeez"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-github opacity-container-child"
                >
                  <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4"></path>
                  <path d="M9 18c-4.51 2-5-2-7-2"></path>
                </svg>
              </a>
              <a
                target="_blank"
                className="social-media-icon"
                rel="noopener noreferrer"
                href="https://www.instagram.com/_amrit_itsaadeez/"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-instagram opacity-container-child"
                >
                  <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
                </svg>
              </a>
              <a
                target="_blank"
                className="social-media-icon"
                rel="noopener noreferrer"
                href="mailto: amrtrj68@email.com"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-mail opacity-container-child"
                >
                  <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                  <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                </svg>
              </a>
              <a
                target="_blank"
                className="social-media-icon"
                rel="noopener noreferrer"
                href=" https://x.com/amrtrj68"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="lucide lucide-twitter opacity-container-child"
                >
                  <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="ar-spacer-hr-sm"></div>

          <div className="col-lg-5 contact-me">
            <span className="available-for-work-bladge" data-aos="fade-in">
              {" "}
              <span class="blinking-dot"></span> Available for work
            </span>
            <div className="ar-spacer-hr-sm"></div>

            <h5
              className="ar-highlighted-text ar-section-heading"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Get in Touch Today
            </h5>
            <h1 data-aos="fade-up" data-aos-delay="200">
              Let's start a project together
            </h1>

            <div
              className="contact-info"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <h6 className="ar-light-grey">
                Feel free to reach out anytime! Whether you have a project in
                mind or just want to say hello, I'd love to hear from you.
                Contact me, and I'll get back to you as soon as possible.
              </h6>
            </div>
            <div className="ar-spacer-hr-sm" />

            <div
              className="row display-icons"
              data-aos="fade-up"
              data-aos-delay="400"
              style={{ gap: "20px !important", width: "100%", margin: "auto" }}
            >
              <div
                className="col-12 ar-flex-start footer-bottom-icons"
                style={{ justifyContent: "flex-start" }}
              >
                {[
                  {
                    href: "https://www.linkedin.com/in/amrit-raj-611447128/",
                    icon: "linkedin",
                  },
                  { href: "https://github.com/amritaadeez", icon: "github" },
                  {
                    href: "https://www.instagram.com/_amrit_itsaadeez/",
                    icon: "instagram",
                  },
                  { href: "mailto:amrtrj68@email.com", icon: "mail" },
                  { href: "https://x.com/amrtrj68", icon: "twitter" },
                ].map(({ href, icon }, index) => (
                  <React.Fragment key={icon}>
                    <a
                      target="_blank"
                      className="social-media-icon"
                      rel="noopener noreferrer"
                      href={href}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`lucide lucide-${icon} opacity-container-child`}
                      >
                        {icon === "linkedin" && (
                          <>
                            <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                            <rect width="4" height="12" x="2" y="9"></rect>
                            <circle cx="4" cy="4" r="2"></circle>
                          </>
                        )}
                        {icon === "github" && (
                          <>
                            <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4"></path>
                            <path d="M9 18c-4.51 2-5-2-7-2"></path>
                          </>
                        )}
                        {icon === "instagram" && (
                          <>
                            <rect
                              width="20"
                              height="20"
                              x="2"
                              y="2"
                              rx="5"
                              ry="5"
                            ></rect>
                            <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                            <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
                          </>
                        )}
                        {icon === "mail" && (
                          <>
                            <rect
                              width="20"
                              height="16"
                              x="2"
                              y="4"
                              rx="2"
                            ></rect>
                            <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                          </>
                        )}
                        {icon === "twitter" && (
                          <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                        )}
                      </svg>
                    </a>
                    {index < 4 && <span className="separator"></span>}
                  </React.Fragment>
                ))}
              </div>
            </div>
            <div className="ar-spacer-hr-sm" />
            <div className="ar-spacer-hr-sm mob-view" />
          </div>
          <div
            className="col-lg-7 col-xl-7 col-12 basic-gradient-right"
            style={{ padding: "1.5rem" }}
            data-aos="fade-up"
            data-aos-delay="500"
          >
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className={
                        errors.firstName ? "form-control error" : "form-control"
                      }
                      placeholder="First Name"
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    <span>
                      {errors.firstName && (
                        <p className="error">{errors.firstName}</p>
                      )}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className={
                        errors.lastName ? "form-control error" : "form-control"
                      }
                      placeholder="Last Name"
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    <span>
                      {errors.lastName && (
                        <p className="error">{errors.lastName}</p>
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="phone">Phone Number</label>
                    <input
                      type="text"
                      id="phone"
                      maxLength="10"
                      name="phone"
                      className={
                        errors.phone ? "form-control error" : "form-control"
                      }
                      placeholder="Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                    <span>
                      {errors.phone && <p className="error">{errors.phone}</p>}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-6">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className={
                        errors.email ? "form-control error" : "form-control"
                      }
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <span>
                      {errors.email && <p className="error">{errors.email}</p>}
                    </span>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  name="message"
                  className={
                    errors.message ? "form-control error" : "form-control"
                  }
                  placeholder="Your Message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
                <span>
                  {errors.message && <p className="error">{errors.message}</p>}
                </span>
              </div>
              {isLoading ? (
                <button
                  disabled
                  style={{ opacity: 0.6 }}
                  className="normal-btn"
                >
                  Submitting{" "}
                  <FaSpinner
                    style={{
                      fontSize: "15px",
                      color: "var(--text-light)",
                      
                      animation: "spin 1s linear infinite",
                    }}
                  />
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn-flip transparent-bg-btn"
                  data-back="Contact Us"
                  data-front="Send Details"
                ></button>
              )}
            </form>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-right" // Position the toast at the bottom-center
        autoClose={2000} // Auto close after 5 seconds
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="ar-spacer-hr-lg" />
    </div>
  );
}

export default Contact;
