import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/Carousel.css"; // Ensure your styles are correct and applied
import anuLinkedIn from "../assets/anu-ln.jpeg";
import gauravLinkedIn from "../assets/gaurav-ln.jpeg";
import devrajLinkedIn from "../assets/devraj-ln.jpeg";
import rohitnarayanLinkedIn from "../assets/rohitnarayan.jpg";

const settings = {
  dots: true,
  infinite: true,
  speed: 1000,
  slidesToScroll: 1,
  className: "center",
  centerMode: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
      },
    },
  ],
};

const slideData = [
  {
    index: 0,
    name: "Anu Priya",
    position: "Senior Developer",
    company: "@Excelsoft",
    src: anuLinkedIn,
    feedback:
      "I have had the pleasure of working with Amrit in the same team, and he is an exceptional teammate. His expertise in frontend technologies such as JavaScript, CSS, HTML, and Angular is impressive. Amrit not only excels in his current skill set but is also constantly eager to learn new things, which makes him an invaluable asset to our team. His collaborative nature and dedication to delivering high-quality work have been crucial in our project’s success. I highly recommend Amrit for any challenging role.",
  },
  {
    index: 1,
    name: "Gaurav Kerkar",
    position: "Software Engineer",
    company: "@Oneshield",
    src: gauravLinkedIn,
    feedback:
      "I have had the pleasure of working with Amrit in the same team, and he is an exceptional teammate. His expertise in frontend technologies such as JavaScript, CSS, HTML, and Angular is impressive. Amrit not only excels in his current skill set but is also constantly eager to learn new things, which makes him an invaluable asset to our team. His collaborative nature and dedication to delivering high-quality work have been crucial in our project’s success. I highly recommend Amrit for any challenging role.",
  },
  {
    index: 2,
    name: "Devraj Chatribin",
    position: "Software Engineer",
    company: "@Oneshield",
    src: devrajLinkedIn,
    feedback:
      "I highly recommend Amrit Raj as a skilled software developer. His expertise in frontend technologies, particularly Angular and JavaScript frameworks, consistently led to the creation of high-quality user interfaces. Amrit is also eager to expand his knowledge in backend development, showing great initiative in learning Java and other technologies. He consistently ensures that the design and functionality of web applications are of the highest standard, optimizing for both performance and user experience. Amrit is not only talented but also a great team player, always willing to help and collaborate. His positive attitude and work ethic make him an invaluable asset to any team. I’m confident he will continue to excel in his career.",
  },
  {
    index: 3,
    name: "Rohit Narayan",
    position: "Senior Software Engineer",
    company: "@Perforce Software",
    src: rohitnarayanLinkedIn,
    feedback:
      "It is with genuine pleasure that I recommend Amrit for any suitable software development role. I had the opportunity to work alongside him as a fellow Software Developer at mLabs for 2 years, where we collaborated on several projects involving AngularJS, Angular and Node.js. Amrit is a highly skilled and dedicated developer with a strong grasp of both AngularJS and the more recent Angular framework. He consistently demonstrated a deep understanding of front-end development principles and best practices. I was particularly impressed by his ability to create responsive and pixel perfect UI. Amrit is a proactive problem-solver, always willing to go the extra mile to ensure project deadlines are met and quality standards are exceeded. He is also a valuable team player, contributing positively to our collaborative environment with his positive attitude and always smiling face. I am confident that Amrit will be a valuable asset to any team. His technical skills, combined with his dedication and positive attitude, make him an excellent candidate for any challenging software development role. Please feel free to contact me if you have any further questions. Sincerely, Rohit Narayan ",
  },
];
const truncateText = (text, length) => {
  return text.length > length ? `${text.substring(0, length)}...` : text;
};

const Carousel = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="carousel">
      <Slider {...settings}>
        {slideData.map((slide) => (
          <div key={slide.index}>
            <div className="slide_image-wrapper">
              <div className="cards slide__image-wrapper">
                <div className=" ar-flex-between">
                  <div className="ar-flex ar-align-items-center">
                    <img
                      className="slide__image"
                      alt={slide.name}
                      src={slide.src}
                      onLoad={(e) => (e.target.style.opacity = 1)}
                    />

                    <div className=" padding-left-1">
                      <h4
                        className="margin-0 web-view"
                        style={{ color: "var(--primary-color)" }}
                      >
                        {slide.name}
                      </h4>
                      <h6
                        className="margin-0 mob-view"
                        style={{ color: "var(--primary-color)" }}
                      >
                        {slide.name}
                      </h6>
                      <h6
                        className="ar-light-grey web-view"
                        style={{ fontSize: "1.25rem" }}
                      >
                        {slide.position} | {slide.company}
                      </h6>
                      <h6
                        className="ar-light-grey mob-view"
                        style={{ fontSize: "1rem", margin: 0 }}
                      >
                        {slide.position}
                      </h6>
                      <h6
                        className="ar-light-grey mob-view"
                        style={{ fontSize: "1rem" }}
                      >
                        {slide.company}
                      </h6>
                    </div>
                  </div>
                  <div className=" padding-left-1">
                    <h6
                      className="right-10 count-fontsize"
                    >
                      {slide.index + 1}/{slideData.length}
                    </h6>
                  </div>
                </div>
                <div className="ar-spacer-hr-sm"></div>
                <div className="row">
                  <div
                    className="col-12 feedback-section"
                    // style={{ height: "150px", overflow: "auto" }}
                  >
                    <p
                      className={`card__text ${
                        expandedIndex === slide.index ? "expanded" : "truncated"
                      }`}
                    >
                      <span style={{ fontSize: "20px" }}>“</span>
                
                        {expandedIndex === slide.index
                          ? slide.feedback
                          : truncateText(slide.feedback, 300)}
                     
                      <span style={{ fontSize: "20px" }}>”</span>
                      {slide.feedback.length > 300 && (
                        <button
                          className="view-more-btn margin-0"
                          style={{margin: "0 !important"}}
                          onClick={() => toggleExpand(slide.index)}
                        >
                          {expandedIndex === slide.index
                            ? "view less"
                            : "view more"}
                        </button>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
