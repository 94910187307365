import React from 'react';
import { 
  FaTrophy, 
  FaStar, 
  FaAward, 
  FaFileAlt, 
  FaCertificate, 
  FaDownload, 
  FaMedal, 
  FaCode 
} from 'react-icons/fa';
import rewards from '../assets/pdf/amrit_raj_recommendation.pdf';
import Certificate from '../assets/pdf/amrit_certificate.pdf';
import '../styles/Awards.css';

const Awards = () => {
  return (
    <div className="section awards-section">
      <div className="row">
        <div className="col-12 col-lg-4 col-xl-4">
          <div data-aos="fade-up">
            <h5 className="ar-highlighted-text ar-section-heading">
              Milestones of Excellence
            </h5>
            <h1 className="inconsolata-family">Awards & Recognitions</h1>
            <p className="inconsolata-family ar-light-grey">
              Recognitions from the Company I worked with
            </p>
          </div>
        </div>

        <div className="col-12 col-lg-8 col-xl-8">
          <div className="awards-container">
            <div className="award-card" data-aos="fade-up" data-aos-delay="100">
              <div className="award-icon">
                <FaTrophy className="icon-primary" />
              </div>
              <div className="award-content">
                <div className="award-header">
                  <h3>Employee Excellence Awards</h3>
                  <span className="award-date">Apr - June 2024</span>
                  </div>
                <p className="award-description">
                  Recognized for outstanding performance and exceptional contribution to project success.
                </p>
                {/* </div> */}
                <div className="award-files">
                  <div className="award-item">
                    <div className="award-badge">
                      <FaFileAlt className="badge-icon" />
                      Appreciation Note
                    </div>
                    <a href={rewards} download className="download-link">
                      <FaDownload className="download-icon" />
                    </a>
                  </div>
                  <div className="award-item">
                    <div className="award-badge">
                      <FaCertificate className="badge-icon" />
                      Certificate
                    </div>
                    <a href={Certificate} download className="download-link">
                      <FaDownload className="download-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="award-card" data-aos="fade-up" data-aos-delay="200">
              <div className="award-icon">
                <FaStar className="icon-primary" />
              </div>
              <div className="award-content">
                <div className="award-header">
                  <h3 style={{marginBottom: "0"}}>Star Performer of the Quarter</h3>
                  <p className="award-date">Sept - Dec 2022</p>
                </div>
                <p className="award-description">
                  Awarded for delivering exceptional results and maintaining high performance standards.
                </p>
                <div className="award-badge">
                  <FaMedal className="badge-icon" />
                  Top Performer
                </div>
              </div>
            </div>

            <div className="award-card" data-aos="fade-up" data-aos-delay="300">
              <div className="award-icon">
                <FaAward className="icon-primary" />
              </div>
              <div className="award-content">
                <div className="award-header">
                  <h3>Technical Excellence Award</h3>
                  <span className="award-date">Jan - Mar 2023</span>
                </div>
                <p className="award-description">
                  Recognized for implementing innovative solutions and technical leadership.
                </p>
                <div className="award-badge">
                  <FaCode className="badge-icon" />
                  Technical Expert
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Awards;




