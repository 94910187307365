import React, { useEffect } from "react";
import jktechLogo from "../assets/logo/jktech.png";
import erouteLogo from "../assets/logo/eroute.png";
import mLabsLogo from "../assets/logo/mLabs.png";
import igniteLogo from "../assets/logo/igniteSol.png";
import { FaTimes } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import '../styles/Modal.css';
const images = {
  igniteLogo,
  jktechLogo,
  erouteLogo,
  mLabsLogo,
};

const WorkHistoryModal = ({ show, handleClose, experience }) => {
  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [show]);

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.95,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.2,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      transition: {
        duration: 0.1,
        ease: "easeIn",
      },
    },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          className="modal-overlay"
          variants={overlayVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          onClick={(e) => {
            if (e.target === e.currentTarget) handleClose();
          }}
          role="dialog"
          aria-modal="true"
          aria-labelledby="workHistoryModalLabel"
        >
          <motion.div
            className="modal-container"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {experience ? (
              <div className="modal-content">
                 <button
                    type="button"
                    className="modal-close-btn"
                    onClick={handleClose}
                    aria-label="Close modal"
                  >
                    <FaTimes />
                  </button>
                <div className="modal-header">
                  <div className="company-banner">
                    <motion.img
                      src={images[experience.logo]}
                      alt={experience.company}
                      className="company-logo"
                      initial={{ scale: 0.8 }}
                      animate={{ scale: 1 }}
                      transition={{ duration: 0.3 }}
                    />
                    <div style={{flexDirection: 'column', gap:'0'}}
                      className="company-info"
                    >
                      <h1 className="company-name">{experience.company}</h1>
                      <h3 className="job-title" style={{margin:'0'}}>{experience.title}</h3>
                      <div className="timeline">
                        <span className="date-range">{experience.dateRange}</span>
                        <span className="experience-duration">
                          ({experience.experience})
                        </span>
                      </div>
                    </div>
                  </div>
                 
                </div>

                <motion.div
                  className="modal-body"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3 }}
                >
                  <div className="experience-details">
                    <ul className="responsibility-list">
                      {experience.detailedDescription.map((point, index) => (
                        <motion.li
                          key={index}
                          initial={{ x: -20, opacity: 0 }}
                          animate={{ x: 0, opacity: 1 }}
                          transition={{ delay: 0.4 + index * 0.1 }}
                          className="responsibility-item"
                        >
                          {point}
                        </motion.li>
                      ))}
                    </ul>
                  </div>
                </motion.div>
              </div>
            ) : (
              <div className="modal-content empty-state">
                <p>No experience details available.</p>
              </div>
            )}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WorkHistoryModal;
