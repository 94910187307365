import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const apiService = {
  // Method for submitting the contact form
  submitContactForm: async (formData) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/contact`, formData);
      return response.data;
    } catch (error) {
      console.error('Error submitting contact form:', error);
      throw error;
    }
  },

  getUsers:async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/users`);
      console.log(response)
      return response.data;
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },

  deleteContact: async (id) => {
    try {
      console.log('Attempting to delete contact with ID:', id);
      const response = await axios.delete(`${API_BASE_URL}/contact/${id}`);
      console.log('Delete response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Delete error details:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      throw error.response?.data || error;
    }
  },

  // Add more API methods as needed
};

export default apiService;
