import React, { useContext, useRef, useEffect, useState } from "react";
// import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "../styles/Profile.css";
import WorkHistoryModal from "../context/Modal";
import Skills from "../context/Skills";
import Awards from "../components/Awards";
import workdata from "../widget/workdata.json";

import {
  FaClipboardList,
  FaChartBar,
  FaPalette,
  FaCode,
  FaTools,
  FaServer,
  FaArrowUp,
  FaBriefcase,
  FaCalendar,
  FaDownload,
  FaExternalLinkAlt, FaArrowRight, FaTrophy, FaFileAlt, FaCertificate, FaStar, FaMedal, FaAward
} from "react-icons/fa";

// icons
import angular from "../assets/icons/angular.svg";
import react from "../assets/icons/react.svg";
import ionic from "../assets/icons/ionic.svg";
import Bootstrap from "../assets/icons/bootstrap.svg";
import primeNg from "../assets/icons/primeng.svg";
import html from "../assets/icons/html.svg";
import css from "../assets/icons/css.svg";
import GSAP from "../assets/icons/gsap.svg";
import JavaScript from "../assets/icons/j.svg";
import TypeScript from "../assets/icons/typescript.svg";
import cypress from "../assets/icons/cypress.svg";
import karma from "../assets/icons/karma.svg";
import devops from "../assets/icons/devops.svg";
import eclipse from "../assets/icons/eclipse.svg";
import vsCode from "../assets/icons/visual-studio.svg";
import figma from "../assets/icons/figma.svg";
import github from "../assets/icons/github.svg";
import xcode from "../assets/icons/xcode.svg";
import winscp from "../assets/icons/winscp.jpeg";
import java from "../assets/icons/java.svg";
import swift from "../assets/icons/swift.svg";
import jenkins from "../assets/icons/jenkins.svg";
import plSql from "../assets/icons/plSql.svg";
import jasmine from "../assets/icons/jasmine.svg";
import sqlDeveloper from "../assets/icons/sqldevloper.svg";
import postman from "../assets/icons/postman.svg";
import git from "../assets/icons/git.svg";
import node from "../assets/icons/node.svg";

import jktechLogo from "../assets/logo/jktech.png";
import erouteLogo from "../assets/logo/eroute.png";
import mLabsLogo from "../assets/logo/mLabs.png";
import igniteLogo from "../assets/logo/igniteSol.png";

//PDF
import resume from "../assets/pdf/resume_amrit_raj.pdf";
import rewards from "../assets/pdf/amrit_raj_recommendation.pdf";
import Certificate from "../assets/pdf/amrit_certificate.pdf";
import { SiAdobephotoshop, SiCanva, SiPrimeng } from "react-icons/si";
import { FiFigma } from "react-icons/fi";
import ResumeModal from '../components/ResumeModal';

function Profile() {
  const images = {
    igniteLogo,
    jktechLogo,
    erouteLogo,
    mLabsLogo,
  };
  useEffect(() => {
    AOS.init({
      duration: 500, // Animation occurs only once
    });
  }, []);

  const [activeIndex, setActiveIndex] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  const handleShow = (experience) => {
    console.log(experience);
    setSelectedExperience(experience);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedExperience(null);
  };

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to a specific page
    navigate("/profile");

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [navigate]);

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [showResumeModal, setShowResumeModal] = useState(false);

  return (
    <div
      className="section"
      style={{ marginTop: "-100px", overflowX: "hidden" }}
    >
     
      {/* main section */}
      <span className="jump-letter ar-step-count bg-name right-10 web-view">
        AMRIT{" "}
      </span>
      <header id="home" class="background-image-amrit">
        <div className="row ar-section-padding">
      <ResumeModal 
                  show={showResumeModal} 
                  onClose={() => setShowResumeModal(false)} 
                />
          <div className="col-lg-6 col-xl-6 col-12 " data-aos="fade-left"></div>
          <div className="col-md-12 col-lg-6 col-xl-6 col-sm-12 min-height-100">
            <div className="row display-icons">
              <h1 className="ar-primary-text" data-aos="fade-right">
                <span className="ar-highlighted-text ">A</span>{" "}
                <span className=""> Senior Developer {""}</span>
                <span className="ar-highlighted-text ">
                  Transforming Ideas into Reality with Precision
                </span>
              </h1>
              <h6
                className="profile-center "
                style={{ opacity: "0.7" }}
                data-aos="fade-left"
              >
                My collaboration with global brands focuses on crafting
                impactful websites that drive success and fulfill strategic
                business objectives.
              </h6>
              <div className="ar-spacer-hr-sm"></div>
              <div className="col-12 col-lg-5 col-xl-4 profile-center view-resume">
                <button
                  className="btn-flip transparent-bg-btn"
                  data-back="Click to View"
                  data-front="View Resume"
                  onClick={() => setShowResumeModal(true)}
                />
                
              </div>
              <div className="col-12 col-lg-7 col-xl-8 footer-bottom-icons profile-icons">
                <a
                  className="social-media-icon"
                  href={resume}
                  download
                  title="download Resume"
                >
                  <FaDownload />
                </a>
                <a
                  target="_blank"
                  className="social-media-icon"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/amrit-raj-611447128/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-linkedin opacity-container-child"
                  >
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect width="4" height="12" x="2" y="9"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                  </svg>
                </a>
                <a
                  target="_blank"
                  className="social-media-icon"
                  rel="noopener noreferrer"
                  href="https://github.com/amritaadeez"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-github opacity-container-child"
                  >
                    <path d="M15 22v-4a4.8 4.8 0 0 0-1-3.5c3 0 6-2 6-5.5.08-1.25-.27-2.48-1-3.5.28-1.15.28-2.35 0-3.5 0 0-1 0-3 1.5-2.64-.5-5.36-.5-8 0C6 2 5 2 5 2c-.3 1.15-.3 2.35 0 3.5A5.403 5.403 0 0 0 4 9c0 3.5 3 5.5 6 5.5-.39.49-.68 1.05-.85 1.65-.17.6-.22 1.23-.15 1.85v4"></path>
                    <path d="M9 18c-4.51 2-5-2-7-2"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  className="social-media-icon"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/_amrit_itsaadeez/"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-instagram opacity-container-child"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" x2="17.51" y1="6.5" y2="6.5"></line>
                  </svg>
                </a>
                <a
                  target="_blank"
                  className="social-media-icon"
                  rel="noopener noreferrer"
                  href="mailto: amrtrj68@email.com"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-mail opacity-container-child"
                  >
                    <rect width="20" height="16" x="2" y="4" rx="2"></rect>
                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
                  </svg>
                </a>
                <a
                  target="_blank"
                  className="social-media-icon"
                  rel="noopener noreferrer"
                  href=" https://x.com/amrtrj68"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="lucide lucide-twitter opacity-container-child"
                  >
                    <path d="M22 4s-.7 2.1-2 3.4c1.6 10-9.4 17.3-18 11.6 2.2.1 4.4-.6 6-2C3 15.5.5 9.6 3 5c2.2 2.6 5.6 4.1 9 4-.9-4.2 4-6.6 7-3.8 1.1 0 3-1.2 3-1.2z"></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* expertise marquee section starts */}

      <div className="section key-strenths">
        <div className="row">
          <div className="col-12">
            <div
              width="100%"
              className="ar-flex-arround inconsolata-family bold-900 opacity-half marquee marquee-profile"
            >
              <div className="marquee-icons">
                <img src={angular} alt="icon" /> Angular
              </div>
              <div className="marquee-icons">
                <img src={react} alt="icon" /> React{" "}
              </div>
              <div className="marquee-icons">
                <img src={ionic} alt="icon" /> Ionic{" "}
              </div>
              <div className="marquee-icons">
                <SiPrimeng /> PrimeNg{" "}
              </div>
              <div className="marquee-icons">
                <img src={GSAP} title="GSAP" alt="icon" /> GSAP{" "}
              </div>
              <div className="marquee-icons">
                <img src={Bootstrap} alt="icon" /> Bootstrap{" "}
              </div>
              <div className="marquee-icons">
                <img src={html} alt="icon" /> HTML{" "}
              </div>
              <div className="marquee-icons">
                <img src={css} alt="icon" /> CSS{" "}
              </div>
              <div className="marquee-icons">
                <img src={JavaScript} alt="icon" /> JavaScript{" "}
              </div>
              <div className="marquee-icons">
                <img src={java} alt="icon" /> Java{" "}
              </div>
              <div className="marquee-icons">
                <img src={swift} alt="icon" /> Swift{" "}
              </div>
              <div className="marquee-icons">
                <img src={TypeScript} alt="icon" /> TypeScript{" "}
              </div>
              <div className="marquee-icons">
                <img src={karma} alt="icon" /> Karma{" "}
              </div>
              <div className="marquee-icons">
                <img src={jasmine} alt="icon" /> Jasmine{" "}
              </div>
              <div className="marquee-icons">
                <img src={cypress} alt="icon" /> Cypress{" "}
              </div>
              <div className="marquee-icons">
                <img src={plSql} alt="icon" /> PL/SQL{" "}
              </div>
              <div className="marquee-icons">
                <img src={sqlDeveloper} alt="icon" /> SQLDeveloper{" "}
              </div>
              <div className="marquee-icons">
                <img src={devops} alt="icon" /> DevOps{" "}
              </div>
              <div className="marquee-icons">
                <img src={github} alt="icon" /> GitHub{" "}
              </div>
              <div className="marquee-icons">
                <img src={vsCode} alt="icon" /> VSCode{" "}
              </div>
              <div className="marquee-icons">
                <img src={eclipse} alt="icon" /> Eclipse{" "}
              </div>
              <div className="marquee-icons">
                <img src={xcode} alt="icon" /> XCode{" "}
              </div>
              <div className="marquee-icons">
                <img src={figma} alt="icon" /> Figma{" "}
              </div>
              <div className="marquee-icons">
                <img src={jenkins} alt="icon" /> Jenkins{" "}
              </div>
              <div className="marquee-icons">
                <img src={winscp} alt="icon" /> WINSCP{" "}
              </div>
              <div className="marquee-icons">
                <img src={postman} alt="icon" /> Postman{" "}
              </div>
              <div className="marquee-icons">
                <img src={git} alt="icon" /> Git{" "}
              </div>
              <div className="marquee-icons">
                <img src={node} alt="icon" /> Node{" "}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
      <div className="ar-spacer-hr-sm"></div>
      <div className="ar-add-seperator basic-gradient-left"></div>
      <div className="ar-spacer-hr-sm"></div>
        <div className="section testimonials-info">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-4">
              <div data-aos="fade-left">
                <h5 className="ar-highlighted-text ar-section-heading">
                  Experience That Shapes My Work
                </h5>
                <h1 className="inconsolata-family">Career Experience</h1>
                <p className="inconsolata-family ar-light-grey">
                  Worked alongside industry trailblazers to build exceptional
                  and forward-thinking products.
                </p>
                <h1
                  className="ar-step-count"
                  style={{ fontSize: "200px", textAlign: "left" }}
                >
                  06+{" "}
                </h1>
                <span
                  className="ar-step-count"
                  style={{ fontSize: "30px", textAlign: "left" }}
                >
                  Years of Experiences
                </span>
              </div>
              <div className="ar-spacer-hr-sm"></div>
            </div>
            <div
              className="col-12 col-lg-1 col-xl-1 "
              style={{ textAlign: "left" }}
              data-aos="fade-down"
            ></div>

            <div
              className="col-12 col-lg-7 col-xl-7"
              data-aos="fade-down"
            >
              <div className="experience-timeline">
                {workdata.map((experience, index) => (
                  <div 
                    key={index} 
                    className="experience-card" 
                    data-aos="fade-left"
                    onClick={() => handleShow(experience)}
                  >
                    <div className="company-info">
                      <div className="company-logo">
                        <img
                          src={images[experience.logo]}
                          alt={experience.company}
                          className="company-logo-img"
                        />
                      </div>
                      
                      <div className="position-details">
                        <h4 className="position-title">
                          {experience.title}
                          <div className="hover-details">
                            <button className="details-btn" onClick={() => handleShow(experience)}>
                              View Details <FaArrowRight />
                            </button>
                            <a
                              href={experience.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="company-site-btn"
                              onClick={(e) => e.stopPropagation()}
                              style={{textDecoration: 'none'}}
                            >
                               Company Site <FaExternalLinkAlt />
                            </a>
                          </div>
                        </h4>
                        
                        <div className="company-meta">
                          <div className="meta-item">
                            <FaBriefcase className="meta-icon" />
                            {experience.experience}
                          </div>
                          <div className="meta-item">
                            <FaCalendar className="meta-icon" />
                            {experience.dateRange}
                          </div>
                        </div>

                        <a
                          href={experience.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="company-link"
                          onClick={(e) => e.stopPropagation()}
                        >
                          @{experience.company}
                          <FaExternalLinkAlt size={12} />
                        </a>
                      </div>
                    </div>

                    <div className="experience-content" style={{paddingTop:'1rem'}}>
                      <p className="experience-description" style={{margin: 0}}>{experience.description}</p>
                      
                      {experience.skills && (
                        <div className="skills-tags">
                          {experience.skills.slice(0, 4).map((skill, idx) => (
                            <span key={idx} className="skill-tag">{skill}</span>
                          ))}
                          {experience.skills.length > 4 && (
                            <span className="skill-tag more-skills">+{experience.skills.length - 4}</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="ar-spacer-hr-sm"></div>
          </div>
        </div>
        <div className="ar-spacer-hr-sm"></div>
      <div className="ar-add-seperator basic-gradient-right"></div>
      <div className="ar-spacer-hr-sm"></div>
        <div className="section ">
          <div className="row">
            <div className="col-12">
              <div data-aos="fade-left">
                <h5 className="ar-highlighted-text ar-section-heading">
                  Transforming Ideas into Reality
                </h5>
                <h1 className="inconsolata-family">
                  Here's how I can assist you
                </h1>
                <p className="inconsolata-family ar-light-grey">
                  I specialize in creating Web and Mobile applications using
                  modern technologies, offering a comprehensive approach to
                  development tailored to meet your unique business needs and
                  goals.
                </p>
              </div>
              <div className="ar-spacer-hr-sm"></div>
            </div>
            <div
              className="col-12"
              style={{ textAlign: "left" }}
              data-aos="fade-down"
            >
              <div className="row">
                <div
                  className="col-12 col-md-6 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card">
                    <h5 className="ar-step-count" style={{ fontSize: "20px" }}>
                      UI/UX
                    </h5>
                    <div className="card-content web-view">
                      Designing innovative wireframes and prototypes with creativity
                    </div>
                    <div className="footer-icons" style={{ paddingBottom: "10px", flexWrap: "wrap" }}>
                      <FiFigma />
                      <SiCanva />
                      <SiAdobephotoshop />
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card">
                    <h5 className="ar-step-count" style={{ fontSize: "20px" }}>
                      Front-End Development
                    </h5>
                    <div className="card-content web-view">
                      Creating responsive, engaging and adaptable websites with cutting-edge technologies.
                    </div>
                    <div className="footer-icons" style={{ paddingBottom: "10px", flexWrap: "wrap" }}>
                      <img src={angular} title="Angular" alt="Angular" />
                      <img src={react} title="React" alt="React" />
                      <img src={GSAP} title="GSAP" alt="GSAP" />
                      <img src={Bootstrap} title="Bootstrap" alt="Bootstrap" />
                      <img src={html} title="HTML" alt="HTML" />
                      <img src={css} title="CSS" alt="CSS" />
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card">
                    <h5 className="ar-step-count" style={{ fontSize: "20px" }}>
                      Mobile Apps Development
                    </h5>
                    <div className="card-content web-view">
                      Developing stunning and user-friendly mobile applications with the latest technologies.
                    </div>
                    <div className="footer-icons" style={{ paddingBottom: "10px", flexWrap: "wrap" }}>
                      <img src={JavaScript} title="JavaScript" alt="JavaScript" />
                      <img src={TypeScript} title="TypeScript" alt="TypeScript" />
                      <img src={swift} title="Swift" alt="Swift" />
                      <img src={ionic} title="Ionic" alt="Ionic" />
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card">
                    <h5 className="ar-step-count" style={{ fontSize: "20px" }}>
                      Backend Development
                    </h5>
                    <div className="card-content web-view">
                      Crafting backend systems with contemporary technologies.
                    </div>
                    <div className="footer-icons" style={{ paddingBottom: "10px", flexWrap: "wrap" }}>
                      <img src={java} title="Java" alt="Java" />
                      <img src={node} title="Java" alt="Node" />
                      <img src={sqlDeveloper} title="SQL" alt="SQL" />
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-md-6 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card">
                    <h5 className="ar-step-count" style={{ fontSize: "20px" }}>
                      Quality Assurance
                    </h5>
                    <div className="card-content web-view">
                      Conducted comprehensive testing of backend systems and APIs with advanced technologies.
                    </div>
                    <div className="footer-icons" style={{ paddingBottom: "10px", flexWrap: "wrap" }}>
                      <img src={cypress} title="Cypress" alt="Cypress" />
                      <img src={karma} title="Karma/Jasmine" alt="Karma/Jasmine" />
                      <img src={jasmine} title="Karma/Jasmine" alt="Karma/Jasmine" />
                      <img src={postman} title="Postman" alt="Karma/Jasmine" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ar-spacer-hr-lg"></div>
          </div>
        </div>
        <div className="ar-spacer-hr-sm"></div>
      <div className="ar-add-seperator basic-gradient-left"></div>
      <div className="ar-spacer-hr-sm"></div>
        <div className="section ">
          <div className="row">
            <div className="col-12 col-lg-4 col-xl-4 mob-view">
              <div data-aos="fade-left">
                <h5 className="ar-highlighted-text ar-section-heading">
                  Skill Experties
                </h5>
                <h1 className="inconsolata-family">
                  Proficient in Key Technologies
                </h1>
                <p className="inconsolata-family ar-light-grey">
                  Expertise in Building High-Performance Web and Mobile
                  Applications, with strong proficiency in creating responsive
                  and adaptive interfaces for optimal user experience.
                </p>
              </div>
            </div>
            <div
              className="col-12 col-lg-7 col-xl-7"
              style={{ textAlign: "left" }}
              data-aos="fade-down"
            >
              {" "}
              <Skills />
            </div>

            <div
              className="col-12 col-lg-1 col-xl-1 "
              style={{ textAlign: "left" }}
              data-aos="fade-down"
            ></div>

            <div
              className="col-12 col-lg-4 col-xl-4 web-view"
              style={{ textAlign: "end" }}
            >
              <div data-aos="fade-left">
                <h5 className="ar-highlighted-text ar-section-heading">
                  Skill Experties
                </h5>
                <h1 className="inconsolata-family">
                  Proficient in Key Technologies
                </h1>
                <p className="inconsolata-family ar-light-grey">
                  Expertise in Building High-Performance Web and Mobile
                  Applications, with strong proficiency in creating responsive
                  and adaptive interfaces for optimal user experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="ar-spacer-hr-sm"></div>
      <div className="ar-add-seperator basic-gradient-right"></div>
      <div className="ar-spacer-hr-sm"></div>
        <div className="section ">
          <div className="row">
            <div className="col-12">
              <div data-aos="fade-left">
                <h5 className="ar-highlighted-text ar-section-heading">
                  How I Turn Ideas Into Results
                </h5>
                <h1 className="inconsolata-family">
                  Here’s My Process for Developing Software
                </h1>
                <p className="inconsolata-family ar-light-grey">
                  I use a structured approach that blends thorough research,
                  strategic planning, and iterative design to deliver
                  high-quality, scalable solutions that are refined through
                  continuous feedback and testing.
                </p>
              </div>
              <div className="ar-spacer-hr-sm"></div>
            </div>
            <div
              className="col-12"
              style={{ textAlign: "left" }}
              data-aos="fade-down"
            >
              <div className="row">
                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between">
                      <span className="ar-step-count"> 01 </span>

                      <FaClipboardList className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Requirement Gathering</h6>
                    <div className="card-info">
                      Identify and collect detailed project requirements to
                      ensure a clear understanding of objectives.
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between">
                      <span className="ar-step-count"> 02 </span>
                      <FaChartBar className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Analyze</h6>
                    <div className="card-info">
                      Analyze requirements to define project scope, identify
                      risks, and establish timelines.
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between">
                      <span className="ar-step-count"> 03 </span>
                      <FaPalette className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Design</h6>
                    <div className="card-info">
                      Create detailed design prototypes to visualize the final
                      product and guide development.
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between">
                      <span className="ar-step-count"> 04 </span>
                      <FaCode className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Coding</h6>
                    <div className="card-info">
                      Implement the design into functional code, focusing on
                      optimizing performance and scalability.
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between">
                      <span className="ar-step-count"> 05 </span>
                      <FaTools className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Testing</h6>
                    <div className="card-info">
                      Conduct rigorous testing to identify and fix bugs,
                      ensuring the product meets quality standards.
                    </div>
                  </div>
                </div>

                <div
                  className="col-12 col-lg-4 col-xl-4 card-col"
                  data-aos="fade-in"
                >
                  <div className="expertise-card padding-top-0">
                    <div className="ar-align-items-center ar-flex-between ">
                      <span className="ar-step-count"> 06 </span>
                      <FaServer className="ar-color-primary circle-icon" />
                    </div>
                    <h6 className="ar-color-primary">Operation</h6>
                    <div className="card-info">
                      Deploy and maintain the application, ensuring smooth
                      operation and handling any issues that arise.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ar-spacer-hr-lg"></div>
          </div>
        </div>
        <div className="ar-spacer-hr-sm"></div>
      <div className="ar-add-seperator basic-gradient-left"></div>
      <div className="ar-spacer-hr-sm"></div>
        <Awards />
        <div className="ar-spacer-hr-lg"></div>
      </div>


      {/* Modal */}
      <WorkHistoryModal
        style={{ background: "inherit" }}
        show={showModal}
        handleClose={handleClose}
        experience={selectedExperience}
      />
      {showScrollToTop && (
        <button
          className="ar-info transparent-bg-btn scroll-top-btn web-view"
          onClick={scrollToTop}
        >
          <button className="btn btn-icon">
            {" "}
            <FaArrowUp />{" "}
          </button>
        </button>
      )}
    </div>
  );
}

export default Profile;
