import React, { useState, useEffect } from "react";
import "../styles/Admin.css";
import apiService from "../widget/api";
import Loader from "../context/Loader";
import { FaTrash, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

function Admin() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [jumpToPage, setJumpToPage] = useState('');
  
  const itemsPerPageOptions = [5, 10, 15, 20, 25, 50];

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await apiService.getUsers();
      const sortedUsers = response.sort((a, b) => b.id - a.id);
      setUsers(sortedUsers);
    } catch (error) {
      setError(error);
      toast.error('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this entry?')) return;
    
    setDeleteLoading(id);
    try {
      await apiService.deleteUser(id);
      setUsers(users.filter(user => user._id !== id));
      toast.success('Entry deleted successfully');
    } catch (error) {
      toast.error('Failed to delete entry');
    } finally {
      setDeleteLoading(null);
    }
  };

  const handleJumpToPage = (e) => {
    e.preventDefault();
    const pageNumber = parseInt(jumpToPage);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setJumpToPage('');
    } else {
      toast.error(`Please enter a page number between 1 and ${totalPages}`);
    }
  };

  // Filter function for tabs
  const getFilteredData = () => {
    switch (activeTab) {
      case 'travel':
        return users.filter(user => !user.lastName);
      case 'portfolio':
        return users.filter(user => user.lastName);
      default:
        return users;
    }
  };

  const filteredData = getFilteredData();
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      // Smooth scroll to top of table
      document.querySelector('.table-wrapper').scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Generate page numbers with simplified pagination
  const getPageNumbers = () => {
    const pageNumbers = [];
    
    if (totalPages <= 5) {
      // If 5 or fewer pages, show all
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      // Show current page and one number on each side
      const start = Math.max(1, currentPage - 1);
      const end = Math.min(totalPages, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }
    }
    
    return pageNumbers;
  };

  const handleItemsPerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    // Reset to first page when changing items per page
    setCurrentPage(1);
  };

  const renderTableHeader = () => (
    <thead>
      <tr>
        <th style={{width: '50px'}}>No.</th>
        <th style={{width: '100px'}}>Website</th>
        <th style={{width: '120px'}}>Name</th>
        <th className="hide-mobile" style={{width: '120px'}}>Phone</th>
        <th style={{width: '180px'}}>Email</th>
        <th className="hide-mobile" style={{width: '250px'}}>Message</th>
        <th style={{width: '100px'}}>Date</th>
        <th style={{width: '80px'}}>Actions</th>
      </tr>
    </thead>
  );

  const renderTableRow = (user, index) => (
    <tr key={user._id}>
      <td className="entry-number">
        {indexOfFirstItem + index + 1}
      </td>
      <td>{user.lastName ? 'Portfolio' : 'Travel Blog'}</td>
      <td>{user.firstName}</td>
      <td className="hide-mobile">{user.phone || 'N/A'}</td>
      <td>{user.email}</td>
      <td className="hide-mobile">
        <div className="message-preview" title={user.message}>
          {user.message.length > 50 
            ? `${user.message.substring(0, 50)}...` 
            : user.message
          }
        </div>
      </td>
      <td>{format(new Date(user.date), 'MMM dd, yyyy')}</td>
      <td>
        <button 
          className="action-btn delete"
          onClick={() => handleDelete(user._id)}
          disabled={deleteLoading === user._id}
        >
          {deleteLoading === user._id ? (
            <span className="loading-spinner"></span>
          ) : (
            <>
              <FaTrash />
              <span>Delete</span>
            </>
          )}
        </button>
      </td>
    </tr>
  );

  if (loading) return <Loader />;
  if (error) return <div className="error-message">Error fetching data: {error.message}</div>;
  if (!users.length) return <div className="no-data">No data available.</div>;

  return (
    <div className="admin-dashboard">
      <div className="dashboard-header">
        <div className="dashboard-title">
          <h2>Admin Dashboard</h2>
          <span className="total-entries">Total: {users.length}</span>
        </div>
        
        <div className="dashboard-controls">
          <div className="items-per-page">
            <label>Show:</label>
            <select 
              value={itemsPerPage} 
              onChange={handleItemsPerPageChange}
              className="items-select"
            >
              {itemsPerPageOptions.map(option => (
                <option key={option} value={option}>{option}</option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className="tabs-container">
        <button 
          className={`tab-button ${activeTab === 'all' ? 'active' : ''}`}
          onClick={() => setActiveTab('all')}
        >
          All ({users.length})
        </button>
        <button 
          className={`tab-button ${activeTab === 'travel' ? 'active' : ''}`}
          onClick={() => setActiveTab('travel')}
        >
          Travel ({users.filter(user => !user.lastName).length})
        </button>
        <button 
          className={`tab-button ${activeTab === 'portfolio' ? 'active' : ''}`}
          onClick={() => setActiveTab('portfolio')}
        >
          Portfolio ({users.filter(user => user.lastName).length})
        </button>
      </div>

      <div className="table-container">
        <div className="table-wrapper">
          <table className="data-table">
            {renderTableHeader()}
            <tbody>
              {currentItems.map((user, index) => renderTableRow(user, index))}
            </tbody>
          </table>
        </div>

        <div className="pagination-container">
          <div className="pagination-info">
            <div className="pagination-stats">
              {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, filteredData.length)} of {filteredData.length}
            </div>
            
            <div className="pagination">
              <button 
                className="page-btn page-nav-btn"
                disabled={currentPage === 1}
                onClick={() => paginate(currentPage - 1)}
              >
                <FaChevronLeft />
                <span className="hide-mobile">Previous</span>
              </button>

              {getPageNumbers().map((number) => (
                <button
                  key={`page-${number}`}
                  className={`page-btn ${currentPage === number ? 'active' : ''}`}
                  onClick={() => paginate(number)}
                >
                  {number}
                </button>
              ))}

              <button 
                className="page-btn page-nav-btn"
                disabled={currentPage === totalPages}
                onClick={() => paginate(currentPage + 1)}
              >
                <span className="hide-mobile">Next</span>
                <FaChevronRight />
              </button>
            </div>

            <form className="quick-jump" onSubmit={handleJumpToPage}>
              <span>Go to:</span>
              <input
                type="number"
                min="1"
                max={totalPages}
                value={jumpToPage}
                onChange={(e) => setJumpToPage(e.target.value)}
                placeholder="#"
              />
              <button type="submit">Go</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
