import React from 'react';
import { FaGithub, FaExternalLinkAlt, FaTimes } from 'react-icons/fa';
import '../styles/ProjectDetail.css';

const ProjectDetail = ({ project, onClose }) => {
  if (!project) return null;

  return (
    <div className="project-detail-overlay">
      <div className="project-detail-modal" data-aos="fade-up">
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>

        <div className="project-detail-content">
          <div className="project-detail-header">
            <img src={project.image} alt={project.title} className="project-detail-image" />
            <div className="project-detail-title-section">
              <h2>{project.title}</h2>
              <div className="project-detail-links">
                <button 
                  onClick={() => window.open(project.githubLink, '_blank')}
                  className="project-detail-btn github-btn"
                >
                  <FaGithub /> View Source Code
                </button>
                {project.demoLink && (
                  <button 
                    onClick={() => window.open(project.demoLink, '_blank')}
                    className="project-detail-btn demo-btn"
                  >
                    <FaExternalLinkAlt /> Live Demo
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="project-detail-body">
            <section className="detail-section">
              <h3>Overview</h3>
              <p>{project.description}</p>
            </section>

            <section className="detail-section">
              <h3>Technologies Used</h3>
              <div className="detail-tech-stack">
                {project.technologies.map(tech => (
                  <span key={tech} className="detail-tech-badge">
                    {tech}
                  </span>
                ))}
              </div>
            </section>

            <section className="detail-section">
              <h3>Key Features</h3>
              <ul className="feature-list">
                {project.features?.map((feature, index) => (
                  <li key={index}>{feature}</li>
                )) || (
                  <li>Detailed features coming soon...</li>
                )}
              </ul>
            </section>

            <section className="detail-section">
              <h3>Implementation Details</h3>
              <p>{project.implementation || "Detailed implementation information coming soon..."}</p>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;