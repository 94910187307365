import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageCarousel = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div
      className="col-lg-5 col-xl-5 col-12 first-section-img-div"
      data-aos="fade-left"
    >
      {/* For web */}
      <div className="web-view">
        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt={`creative-idea-${index}`}
                style={{
                  marginLeft: image.isMainImage1 ? "0px" : "80px",
                  height: image.isMainImage1 ? "120%" : "70%",
                  width: image.isMainImage1 ? "100%" : "70%",
                  objectFit: "cover",
                  transform: "rotateY(-180deg)",
                }}
              />
            </div>
          ))}
        </Slider>
      </div>
      {/* for mobile */}
      <div className="mob-view">
        <img
          src={images[1].src}
          alt={`creative-idea-${1}`}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
};

export default ImageCarousel;
