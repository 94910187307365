import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { FaInfoCircle, FaCheck } from "react-icons/fa";
import "../styles/CustomColorPicker.css";
import { motion, AnimatePresence } from "framer-motion";
// Create Theme Context
const ThemeContext = createContext();

// Custom hook for theme context
export const useTheme = () => {
  return useContext(ThemeContext);
};

let colorAlertShown = false;
// Convert hex to RGB and calculate brightness
function getBrightness(color) {
  let r, g, b;
  if (color.startsWith("#")) {
    const hex = color.substring(1);
    r = parseInt(hex.substring(0, 2), 16);
    g = parseInt(hex.substring(2, 4), 16);
    b = parseInt(hex.substring(4, 6), 16);
  } else {
    [r, g, b] = color.match(/\d+/g).map(Number);
  }
  return (r * 299 + g * 587 + b * 114) / 1000; // Luminance formula
}

const hexToRgb = (hex) => {
  hex = hex.replace("#", "");
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  return `${r}, ${g}, ${b}`;
};

// Theme Provider Component
export const CustomColorPicker = ({ children, theme, setTheme }) => {
  const savedColor = localStorage.getItem("custom-theme") || "#d55882";
  const [themeColor, setThemeColor] = useState(savedColor);
  const [isCustomColorActive, setIsCustomColorActive] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    // Check if current color is not in preset colors
    const isCustom = !presetColors.includes(themeColor);
    setIsCustomColorActive(isCustom);
    if (isCustom) {
      setTheme("custom"); // Set theme to custom if color is not in presets
    }
  }, [themeColor, setTheme]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(isExpanded ? contentRef.current.scrollHeight : 0);
    }
  }, [isExpanded]);

  const toggleAccordion = () => {
    setIsExpanded(!isExpanded);
  };

  const presetColors = [
    "#d55882",
    "#0d5075",
    "#0d9274",
    "#9c27b0",
    "#e91e63",
    "#2196f3",
    "#ff5722",
    "#4caf50",
    "#795548",
  ];

  // Function to update theme color and mode
  const updateThemeColor = (newColor) => {
    const brightness = getBrightness(newColor);
    if (brightness < 50 || brightness > 150) {
      return;
    }

    setThemeColor(newColor);
    setTheme("custom"); // Set theme to custom immediately
    localStorage.setItem("custom-theme", newColor);

    // Update CSS variables
    document.documentElement.style.setProperty("--primary-color", newColor);
    document.documentElement.style.setProperty(
      "--primary-color-rgb",
      hexToRgb(newColor)
    );
    document.documentElement.style.setProperty("--border-color", newColor);
    document.documentElement.style.setProperty(
      "--font-primary-color",
      newColor
    );
    document.documentElement.style.setProperty(
      "--button-primary-color",
      newColor
    );
    document.documentElement.style.setProperty("--link-active-color", newColor);
    document.documentElement.style.setProperty("--text-primary", newColor);
    document.documentElement.style.setProperty("--footer-bg", newColor);
    document.documentElement.style.setProperty(
      "--light-primary-gradient-color",
      newColor
    );
  };

  return (
    <ThemeContext.Provider value={{ themeColor, setThemeColor }}>
      <div className="custom-color-section">
        {/* Show when custom color is active */}
        {isCustomColorActive && (
          <div className="active-theme-indicator">
            <div 
              className="color-preview" 
              style={{ backgroundColor: themeColor }}
            />
            <span>Custom Color Active</span>
            <FaCheck className="check-icon" />
          </div>
        )}

        {/* Info text with background */}
        <div className="color-picker-info">
          <div className="color-warning">
            <FaInfoCircle className="info-icon" />
            <span>
              Choose a color that provides good contrast with both light and dark backgrounds.
              The brightness indicator helps ensure readability.
            </span>
          </div>
        </div>

        {/* Accordion color picker */}
        <div className="color-accordion">
          <button 
            className={`accordion-trigger ${isExpanded ? 'expanded' : ''}`}
            onClick={toggleAccordion}
            aria-expanded={isExpanded}
          >
            <div className="accordion-header-content">
              <div 
                className="color-preview" 
                style={{ backgroundColor: themeColor }}
              />
              <span>Customize Color</span>
              <div className="accordion-icon" />
            </div>
          </button>

          <motion.div 
            className="accordion-content"
            animate={{
              height: contentHeight,
              opacity: isExpanded ? 1 : 0,
            }}
            transition={{
              height: {
                duration: 0.3,
                ease: [0.4, 0, 0.2, 1]
              },
              opacity: {
                duration: 0.2,
                delay: isExpanded ? 0.1 : 0
              }
            }}
          >
            <div ref={contentRef} className="accordion-content-inner">
              <div className="color-picker-content">
                <div className="color-preview-section">
                  <div
                    className="large-color-preview"
                    style={{
                      backgroundColor: themeColor,
                      boxShadow: `0 4px 12px ${themeColor}66`,
                    }}
                  />
                  <div className="color-info">
                    <span className="color-hex">{themeColor.toUpperCase()}</span>
                    <div className="brightness-indicator">
                      <div className="brightness-bar">
                        <div
                          className="brightness-fill"
                          style={{
                            width: `${Math.round(getBrightness(themeColor))}%`,
                          }}
                        />
                      </div>
                      <span>
                        {Math.round(getBrightness(themeColor))}% brightness
                      </span>
                    </div>
                  </div>
                </div>

                <div className="color-input-section">
                  <label className="color-input-label">
                    <span>Custom Color</span>
                    <div className="color-input-wrapper">
                      <input
                        type="color"
                        value={themeColor}
                        onChange={(e) => updateThemeColor(e.target.value)}
                        className="color-input"
                      />
                      <div className="color-input-preview" style={{ backgroundColor: themeColor }}>
                        <span>{themeColor.toUpperCase()}</span>
                      </div>
                    </div>
                  </label>
                </div>

                <div className="preset-colors">
                  <label>Quick Colors</label>
                  <div className="preset-colors-grid">
                    {presetColors.map((color, index) => (
                      <button
                        key={index}
                        className={`preset-color-btn ${
                          color === themeColor ? "active" : ""
                        }`}
                        style={{
                          backgroundColor: color,
                          boxShadow: color === themeColor 
                            ? `0 0 0 2px var(--text-dark), 0 0 0 4px ${color}` 
                            : 'none',
                        }}
                        onClick={() => updateThemeColor(color)}
                        title={color}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      {children}
    </ThemeContext.Provider>
  );
};
