import React from 'react';
import { FaDownload } from 'react-icons/fa';
import resume from "../assets/pdf/resume_amrit_raj.pdf";

const ResumeModal = ({ show, onClose }) => {
  if (!show) return null;

  return (
    <div className="resume-modal-overlay" onClick={onClose}>
      <div className="resume-modal-content" onClick={e => e.stopPropagation()}>
        <div className="resume-modal-header">
          <h3>Resume</h3>
          <button className="resume-modal-close" onClick={onClose}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              width="24" 
              height="24" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2" 
              strokeLinecap="round" 
              strokeLinejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </button>
        </div>
        <div className="resume-modal-body" style={{padding: '0'}}>
          <iframe
            src="https://docs.google.com/document/d/1gAqTxUiHihiYp86hcoDklErNyZY4rBJpUCylYrAHaEA/preview"
            width="100%"
            height="100%"
            title="Resume"
          />
          <div className="resume-actions">
            <a href={resume} download className="resume-download-btn" style={{textDecoration:"none"}}>
              <FaDownload /> Download PDF
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeModal;