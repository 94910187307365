import React, { useState, useEffect } from "react";
import "../styles/Projects.css";
import AOS from "aos";
import projectsData from "../widget/projects.json";
import ProjectDetail from "../components/ProjectDetail";
import { FaArrowRight, FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

function Projects() {
  const [activeFilter, setActiveFilter] = useState("All");
  const [selectedProject, setSelectedProject] = useState(null);
  const categories = ["All", "Full Stack", "Frontend"];

  // Filter projects based on active category
  const filteredProjects =
    activeFilter === "All"
      ? projectsData.projects
      : projectsData.projects.filter(
          (project) => project.category === activeFilter
        );

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to a specific page
    navigate("/projects");

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, [navigate]);

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    document.body.style.overflow = "hidden";
  };

  const handleCloseDetail = () => {
    setSelectedProject(null);
    document.body.style.overflow = "auto";
  };

  const getCategoryCounts = () => {
    const counts = {
      all: projectsData.projects.length,
      "Full Stack": projectsData.projects.filter(
        (p) => p.category === "Full Stack"
      ).length,
      Frontend: projectsData.projects.filter((p) => p.category === "Frontend")
        .length,
    };
    return counts;
  };

  const FilterButtons = ({ activeFilter, setActiveFilter, categories }) => {
    return (
      <div className="project-filters">
        {categories.map((category) => (
          <button
            key={category}
            className={`filter-btn ${
              activeFilter === category ? "active" : ""
            }`}
            onClick={() => setActiveFilter(category)}
          >
            {category} (
            {category === "All"
              ? getCategoryCounts().all
              : getCategoryCounts()[category]}
            )
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="inconsolata-family ar-section-padding container">
      <span className="jump-letter ar-step-count bg-name right-10 web-view">
        AMRIT{" "}
      </span>

      <div className="ar-spacer-hr-sm"></div>

      <div className="row">
        <div className="col-lg-4 contact-me sticky-section">
          {/* <span className="available-for-work-bladge" data-aos="fade-in">
            <span className="blinking-dot"></span> Portfolio
          </span> */}
          <div className="ar-spacer-hr-sm"></div>

          <h5
            className="ar-highlighted-text ar-section-heading"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Featured Projects
          </h5>
          <h1 className="section-title">Showcasing My Work</h1>

          <div className="contact-info">
            <h6 className="project-description">
              Explore my portfolio of projects that demonstrate my expertise in
              web development, from responsive frontends to robust backend
              solutions.
            </h6>
          </div>

          <div className="ar-spacer-hr-sm" />

          <FilterButtons
            activeFilter={activeFilter}
            setActiveFilter={setActiveFilter}
            categories={categories}
          />
        </div>

        <div
          className="col-lg-8 basic-gradient-right projects-container"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <div className="projects-grid">
            {filteredProjects.map((project, index) => (
              <div
                className="project-card"
                key={project.id}
                data-aos="fade-up"
                data-aos-delay={index * 100}
              >
                <div className="project-image">
                  {/* Add badge based on project status */}
                  {project.status && (
                    <div className={`project-badge ${project.status.toLowerCase()}`}>
                      <span className="badge-dot"></span>
                      {project.status}
                    </div>
                  )}
                  <img src={project.image} alt={project.title} />
                  <div className="project-links">
                    <button
                      className="project-link-btn view-details"
                      onClick={() => handleProjectClick(project)}
                    >
                      View Project <FaArrowRight />
                    </button>
                  </div>
                </div>
                <div className="project-content">
                  <div className="project-main-content">
                    <h3 className="project-title">{project.title}</h3>
                    <p className="project-description">
                      {project.description.substring(0, 100)}...
                    </p>
                    <div className="tech-stack">
                      {project.technologies.slice(0, 2).map((tech) => (
                        <span key={tech} className="tech-badge">
                          {tech}
                        </span>
                      ))}
                      {project.technologies.length > 2 && (
                        <span className="tech-badge">
                          +{project.technologies.length - 2}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="project-footer">
                    <div className="project-actions">
                      <a
                        href={project.githubLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="project-action-btn tech-badge"
                      >
                        <FaGithub /> GitHub
                      </a>
                      {project.demoLink && (
                        <a
                          href={project.demoLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="project-action-btn tech-badge"
                        >
                          <FaExternalLinkAlt /> Live Demo
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {selectedProject && (
        <ProjectDetail
          project={selectedProject}
          onClose={handleCloseDetail}
          allProjects={filteredProjects}
          handleProjectClick={handleProjectClick}
        />
      )}

      <div className="ar-spacer-hr-lg" />
    </div>
  );
}

export default Projects;
